require('./bootstrap');

import $ from 'jquery';
window.$ = window.jQuery = $;

import 'jquery-ui/ui/widgets/datepicker.js';
import 'jquery-ui/ui/widgets/autocomplete.js';
import 'jquery-ui/ui/core.js';
import 'jquery-ui/ui/effect.js';
import 'jquery-ui/ui/effects/effect-slide.js';

import 'jquery-validation/dist/jquery.validate.js';
import 'jquery-validation/dist/additional-methods.js';

require('./bootnavbar');